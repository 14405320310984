import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import ToggleSwitch from "../../../../Component/Common/ToggleSwitch/ToggleSwitch";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateRegno,
} from "../../../../SupportingFiles/HelpingFunction";
import { TW_ADD_FORM_SERVICES } from "../../../../Services/TW/TWAddFormServices";
import { CAR_ADD_FORM_SERVICES } from "../../../../Services/CAR/CarAddFormServices";
import { TDropdown } from "../../../../Type/Common/TDropdown";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import SearchDropdown from "../../../../Component/Common/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
import { toast } from "react-toastify";
import {
  PIR_DATA_SUMINSURED,
  PIR_DATA_VEHICLE,
} from "../../../../Store/Slice_Reducer/PIR/PirSlice";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import { PIR_FORM_SERVICES } from "../../../../Services/PIR/PirService";
interface IVehicle {
  // step: (s: (v: number) => number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  submitFormHandler: (body: any) => void;
}
const PIRVehicle = ({
  incrementStep,
  decrementStep,
  submitFormHandler,
}: IVehicle) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { HAVEANYVEHICLE, HAVEVEHICLE } = useAppSelector((state) => state.PIR);
  console.log("HAVEANYVEHICLE====>", HAVEVEHICLE);
  const [toggleStatus5, setToggleStatus5] = useState<boolean | undefined>(
    false
  );
  const [haveVehicle, setHaveVehicle] = useState<{
    haveAnyVehicle: { value: string; warning: boolean };
  }>({
    haveAnyVehicle: { value: "No", warning: false },
  });
  type TAddVehicle = {
    // haveAnyVehicle: { value: string; warning?: boolean };
    makeModel: { value: string | any; warning: boolean };
    make_model_data: TDropdown[];
    fuel_type_data: TDropdown[];
    variant_data: TDropdown[];
    regNo: { value: string; warning: boolean };
    fuelType: { value: string | any; warning: boolean };
    variantCC: { value: string | any; warning: boolean };
    previousInsurer: { value: string | any; warning: boolean };
    registrationDate: { value: string | Date; warning: boolean };
    policyExpiryDate: { value: string | Date; warning: boolean };
    vehicleType: { value: "Car" | "Tw" | ""; warning: boolean };
  };
  const [addVehicleDetails, setaddVehicleDetails] = useState<TAddVehicle[]>([
    {
      // haveAnyVehicle: { value: "No", warning: false },
      makeModel: { value: "", warning: false },
      fuelType: { value: "", warning: false },
      variantCC: { value: "", warning: false },
      registrationDate: { value: "", warning: false },
      policyExpiryDate: { value: "", warning: false },
      vehicleType: {
        value: haveVehicle.haveAnyVehicle?.value === "Yes" ? "Tw" : "",
        warning: false,
      },
      previousInsurer: { value: "", warning: false },
      regNo: { value: "", warning: false },
      fuel_type_data: [],
      make_model_data: [],
      variant_data: [],
    },
  ]);

  const [prevInsList, setPrevInsList] = useState<TDropdown[]>([]);
  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;
      // debugger;
      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;
        // data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${value}`, value: `${value}` })
        );

        setPrevInsList(data);
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };
  const GET_MAKE_MODEL_LIST = (index: any) => {
    const onSuccess = (res: any) => {
      const results = res.data.results;
      const error = results.error;

      let data: TDropdown[] = [];
      if (error === false) {
        const response = results.response;
        data = Object.values(response).map((item: any) => ({
          value: item,
          label: item,
        }));
      }

      // dispatch(
      //   CarSlice.actions.SET_DROPDOWN_DATA({
      //     key: "MAKE_MODEL_LIST",
      //     value: data,
      //   })
      // );
      // let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
      // vehicleDetail[index] = {
      //   ...vehicleDetail[index],

      //   make_model_data: data,
      // };
      // console.log("inside GET_MAKE_MODEL_LIST");
      // setaddVehicleDetails(vehicleDetail);
      setaddVehicleDetails((prev) => {
        console.log("indexxx====>", index);
        return prev.map((item, idx) => {
          if (index == idx) {
            GET_FUEL_TYPE_LIST(idx, item.makeModel.value);
            return { ...item, make_model_data: data } as TAddVehicle;
          } else {
            return { ...item };
          }
        });
      });
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };
  const GET_MAKE_MODEL_TW = (index: any) => {
    const onSuccess = (res: any) => {
      // debugger;
      const results = res.data.results;
      const error = results.error;

      let data: TDropdown[] = [];
      if (error === false) {
        const response = results.response;

        data = Object.values(response).map((item: any) => ({
          value: item,
          label: item,
        }));

        // let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
        // vehicleDetail[index] = {
        //   ...vehicleDetail[index],

        //   make_model_data: data,
        // };
        // console.log("inside GET_MAKE_MODEL_TW");

        setaddVehicleDetails((prev) => {
          console.log("indexxx====>", index);
          return prev.map((item, idx) => {
            if (index == idx) {
              GET_FUEL_TYPE_TW(idx, item.makeModel.value);
              return { ...item, make_model_data: data } as TAddVehicle;
            } else {
              return { ...item };
            }
          });
        });
      }
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };

  const GET_FUEL_TYPE_LIST = (index: any, make_model: string) => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = [];
      const response = res.results.response;
      const response_array = Object.entries(response);

      response_array.forEach((elment) => {
        data.push({
          value: `${elment[1]}`,
          label: `${elment[1]}`,
        });
      });
      // let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
      // vehicleDetail[index] = {
      //   ...vehicleDetail[index],
      //   makeModel: { value: make_model, warning: isEmpty(make_model) },
      //   fuelType: { value: "", warning: false },
      //   fuel_type_data: data,
      // };
      // console.log("inside GET_FUEL_TYPE_LIST");
      // setaddVehicleDetails(vehicleDetail);
      setaddVehicleDetails((prev) => {
        console.log("indexxx====>", index);
        return prev.map((item, idx) => {
          if (index == idx) {
            GET_VARIANT_LIST(idx, item.fuelType.value, item.makeModel.value);
            return { ...item, fuel_type_data: data } as TAddVehicle;
          } else {
            return { ...item };
          }
        });
      });
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(onSuccess, onError, make_model);
  };
  const GET_FUEL_TYPE_TW = (index: any, make_model: string) => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = [];
      const response = res.results.response;
      const response_array = Object.entries(response);

      response_array.forEach((elment) => {
        data.push({
          value: `${elment[1]}`,
          label: `${elment[1]}`,
        });
      });

      // let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
      // vehicleDetail[index] = {
      //   ...vehicleDetail[index],
      //   makeModel: { value: make_model, warning: isEmpty(make_model) },
      //   fuelType: { value: "", warning: false },
      //   fuel_type_data: data,
      // };
      console.log("inside GET_FUEL_TYPE_TW");

      // setaddVehicleDetails(vehicleDetail);
      setaddVehicleDetails((prev) => {
        console.log("indexxx====>", index);
        return prev.map((item, idx) => {
          if (index == idx) {
            GET_VARIANT_TW(idx, item.fuelType.value, item.makeModel.value);
            return { ...item, fuel_type_data: data } as TAddVehicle;
          } else {
            return { ...item };
          }
        });
      });
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(onSuccess, onError, make_model);
  };

  const GET_VARIANT_TW = (
    index: any,
    fuel_type: string,
    makeModel?: string
  ) => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        value: item,
        label: item,
      }));
      // debugger;
      let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
      // vehicleDetail[index] = {
      //   ...vehicleDetail[index],
      //   fuelType: { value: fuel_type, warning: isEmpty(fuel_type) },
      //   variant_data: data,
      // };
      // console.log("inside GET_VARIANT_TW");

      // setaddVehicleDetails(vehicleDetail);

      setaddVehicleDetails((prev) => {
        console.log("indexxx====>", index);
        return prev.map((item, idx) => {
          if (index == idx) {
            return { ...item, variant_data: data } as TAddVehicle;
          } else {
            return { ...item };
          }
        });
      });
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      fuel_type,
      `${makeModel || addVehicleDetails[index].makeModel.value}`
    );
  };
  const GET_VARIANT_LIST = (
    index: any,
    fuel_type: string,
    makeModel?: string
  ) => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        value: item,
        label: item,
      }));

      // let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];
      // vehicleDetail[index] = {
      //   ...vehicleDetail[index],
      //   fuelType: { value: fuel_type, warning: isEmpty(fuel_type) },
      //   variant_data: data,
      // };
      // console.log("inside GET_VARIANT_LIST");

      // setaddVehicleDetails(vehicleDetail);
      setaddVehicleDetails((prev) => {
        console.log("indexxx====>", index);
        return prev.map((item, idx) => {
          if (index == idx) {
            return { ...item, variant_data: data } as TAddVehicle;
          } else {
            return { ...item };
          }
        });
      });
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${makeModel || addVehicleDetails[index].makeModel.value}`,
      fuel_type
    );
  };
  useEffect(() => {
    // GET_MAKE_MODEL_LIST([0]);

    GET_MAKE_MODEL_TW(0);
    GET_PREV_INSURER_LIST();
  }, []);
  useEffect(() => {
    console.log("useEffect works", HAVEVEHICLE);
    if (Array.isArray(HAVEVEHICLE)) {
      HAVEVEHICLE.map((vehical, index) => {
        if (
          vehical.vehicleType.value == "Tw" ||
          vehical.vehicleType.value == ""
        ) {
          GET_MAKE_MODEL_TW(index);
        } else {
          GET_MAKE_MODEL_LIST(index);
        }
      });
    }
    setaddVehicleDetails(HAVEVEHICLE);

    // setHaveVehicle(HAVEANYVEHICLE);
  }, [HAVEVEHICLE]);
  useEffect(() => {
    console.log("checking have any vehicle", HAVEANYVEHICLE);
    if (
      HAVEANYVEHICLE?.haveAnyVehicle &&
      HAVEANYVEHICLE?.haveAnyVehicle?.value
    ) {
      console.log("checkinggg", HAVEANYVEHICLE);
      setToggleStatus5(
        HAVEANYVEHICLE.haveAnyVehicle.value == "Yes" ? true : false
      );
      setHaveVehicle({
        haveAnyVehicle: { ...HAVEANYVEHICLE.haveAnyVehicle },
      } as any);
    }
  }, [HAVEANYVEHICLE]);
  const updateToggleStatus = (attrName: any, value: boolean) => {
    setToggleStatus5(value);
    console.log("inside updateToggleStatus");
    setaddVehicleDetails((prev) => {
      prev[0].vehicleType = {
        value: value ? "Tw" : "",
        warning: false,
      };
      prev[0].variantCC = {
        value: "",
        warning: false,
      };
      prev[0].previousInsurer = {
        value: "",
        warning: false,
      };
      prev[0].regNo = {
        value: "",
        warning: false,
      };
      prev[0].makeModel = {
        value: "",
        warning: false,
      };
      prev[0].fuelType = {
        value: "",
        warning: false,
      };
      prev[0].registrationDate = {
        value: "",
        warning: false,
      };
      prev[0].policyExpiryDate = {
        value: "",
        warning: false,
      };
      return [prev[0]];
    });
    // setVehicleDetails((prevFinancial) => ({
    //   ...prevFinancial,
    //   haveAnyVehicle: {
    //     ...prevFinancial.haveAnyVehicle,
    //     value: value ? "Yes" : "No",
    //   },
    // }));
    console.log("heree33333", haveVehicle);
    setHaveVehicle((prevFinancial) => ({
      ...prevFinancial,
      haveAnyVehicle: {
        ...prevFinancial.haveAnyVehicle,
        value: value ? "Yes" : "No",
      },
    }));

    // setHaveVehicle()
    GET_PREV_INSURER_LIST();
  };

  const handleCheckboxToggle = (index: number, label: string) => {
    // GET_MAKE_MODEL_TW(index);
    // debugger;
    console.log("inside handleCheckboxToggle111111");

    setaddVehicleDetails((prev): any => {
      const data = [...prev] as unknown as any;
      data[index].vehicleType = {
        value: label as "Car" | "Tw",
        warning: false,
      };
      return data;
    });

    if (addVehicleDetails[index].vehicleType.value === "Car") {
      console.log("inside handleCheckboxToggle2222222222");

      setaddVehicleDetails((prev) => {
        const data = [...prev];
        data[index].makeModel = { value: "", warning: false };
        data[index].fuelType = { value: "", warning: false };
        data[index].variantCC = { value: "", warning: false };
        data[index].regNo = { value: "", warning: false };
        data[index].previousInsurer = { value: "", warning: false };
        data[index].registrationDate = { value: "", warning: false };
        data[index].policyExpiryDate = { value: "", warning: false };
        return data;
      });
      GET_MAKE_MODEL_LIST(index);
    } else {
      console.log("inside handleCheckboxToggle333333333333");

      setaddVehicleDetails((prev) => {
        const data = [...prev];
        data[index].makeModel = { value: "", warning: false };
        data[index].fuelType = { value: "", warning: false };
        data[index].variantCC = { value: "", warning: false };
        data[index].regNo = { value: "", warning: false };
        data[index].previousInsurer = { value: "", warning: false };
        data[index].registrationDate = { value: "", warning: false };
        data[index].policyExpiryDate = { value: "", warning: false };
        return data;
      });
      GET_MAKE_MODEL_TW(index);
      // GET_PREV_INSURER_LIST(index);
    }
  };
  const removeVehicle = (index: any) => {
    console.log("inside removeVehicle");

    setaddVehicleDetails((prev) => prev.filter((_, i) => i !== index));
  };
  const convertDateToOriginalFormat = (dateString: string | null) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const convertDateFormat = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };
  const updateMasterState = (attrName: string[], value: string) => {
    let newValue: string | number = value;

    if (attrName[0] === "addVehicleDetails") {
      let newValue: string | number = value;
      if (["policyExpiryDate", "registrationDate"].includes(attrName[1])) {
        newValue = convertDateFormat(value);
      }
      let index = attrName[2] as unknown as any;
      if (["regNo"].includes(attrName[1])) {
        let vehicleDetail: TAddVehicle[] = JSON.parse(
          JSON.stringify([...addVehicleDetails])
        );
        let index = attrName[2] as unknown as any;
        let key = attrName[1];
        vehicleDetail[index] = {
          ...vehicleDetail[index],
          [key]: { value: value, warning: !validateRegno(value) },
        };
        console.log("inside updateMasterState");

        setaddVehicleDetails((pv) => vehicleDetail);
      } else {
        let vehicleDetail: TAddVehicle[] = [...addVehicleDetails];

        let key = attrName[1];
        vehicleDetail[index] = {
          ...vehicleDetail[index],
          [key]: { value: newValue, warning: isEmpty(value) },
        };
        console.log("inside updateMasterState22222222");

        setaddVehicleDetails(vehicleDetail);
      }
      if (attrName[1] === "makeModel") {
        if (addVehicleDetails[index].vehicleType.value === "Tw") {
          GET_FUEL_TYPE_TW(attrName[2], value);
        } else {
          GET_FUEL_TYPE_LIST(attrName[2], value);
        }
      }
      if (attrName[1] === "fuelType") {
        if (addVehicleDetails[index].vehicleType.value === "Tw") {
          GET_VARIANT_TW(attrName[2], value);
        } else {
          GET_VARIANT_LIST(attrName[2], value);
        }
      }
    }
  };
  const handleBoxClick1 = () => {
    addVehicleDetails.push({
      // haveAnyVehicle: { value: "No", warning: false },
      makeModel: { value: "", warning: false },
      fuelType: { value: "", warning: false },
      variantCC: { value: "", warning: false },
      registrationDate: { value: "", warning: false },
      policyExpiryDate: { value: "", warning: false },
      previousInsurer: { value: "", warning: false },
      vehicleType: { value: "Tw", warning: false },
      regNo: { value: "", warning: false },
      fuel_type_data: [],
      make_model_data: [],
      variant_data: [],
    });
    GET_MAKE_MODEL_TW(addVehicleDetails.length - 1);
  };
  const handlePrevious = () => {
    decrementStep();
  };
  const handleContinue = () => {
    let local_vehicle_detail_state = [...addVehicleDetails];
    let local_have_any_vehicle_state = { ...haveVehicle };
    // let local_have_any_vehicle_state;
    // if (HAVEANYVEHICLE.haveAnyVehicle.value == "Yes") {
    //   console.log("yes console===>", HAVEANYVEHICLE);
    //   local_have_any_vehicle_state = { ...HAVEANYVEHICLE };
    // } else {
    //   console.log("no console===>", haveVehicle);
    //   local_have_any_vehicle_state = { ...haveVehicle };
    // }

    local_vehicle_detail_state = local_vehicle_detail_state.map((vehicle) => {
      if (toggleStatus5) {
        vehicle.makeModel = {
          ...vehicle.makeModel,
          warning: !vehicle.makeModel.value,
        };
        vehicle.fuelType = {
          ...vehicle.fuelType,
          warning: !vehicle.fuelType.value,
        };
        vehicle.variantCC = {
          ...vehicle.variantCC,
          warning: !vehicle.variantCC.value,
        };
        vehicle.regNo = {
          ...vehicle.regNo,
          warning: !validateRegno(vehicle.regNo.value),
        };
        vehicle.previousInsurer = {
          ...vehicle.previousInsurer,
          warning: !vehicle.previousInsurer.value,
        };
        vehicle.registrationDate = {
          ...vehicle.registrationDate,
          warning: !vehicle.registrationDate.value,
        };
        vehicle.policyExpiryDate = {
          ...vehicle.policyExpiryDate,
          warning: !vehicle.policyExpiryDate.value,
        };
      }

      if (
        vehicle.makeModel.warning ||
        vehicle.fuelType.warning ||
        vehicle.variantCC.warning ||
        vehicle.regNo.warning ||
        vehicle.previousInsurer.warning ||
        vehicle.registrationDate.warning ||
        vehicle.policyExpiryDate.warning
      ) {
        console.log("please Fill all the fields");
      }
      return vehicle;
    });

    let isValid = local_vehicle_detail_state.every((vehicle) => {
      return (
        !vehicle.makeModel.warning &&
        !vehicle.fuelType.warning &&
        !vehicle.variantCC.warning &&
        !vehicle.regNo.warning &&
        !vehicle.previousInsurer.warning &&
        !vehicle.registrationDate.warning &&
        !vehicle.policyExpiryDate.warning
      );
    });
    console.log("inside handle continue");

    setaddVehicleDetails(local_vehicle_detail_state);
    console.log("hereeeee====>", local_have_any_vehicle_state);
    setHaveVehicle(local_have_any_vehicle_state);

    if (isValid) {
      const filteredVehicleDetails = addVehicleDetails?.map((data) => {
        const {
          fuel_type_data,
          make_model_data,
          variant_data,
          // haveAnyVehicle,
          ...otherData
        } = data;
        return otherData;
      });

      const body = {
        HAVEVEHICLE: [...filteredVehicleDetails],
        HAVEANYVEHICLE: { ...haveVehicle },
      };

      dispatch(PIR_DATA_VEHICLE(body));
      submitFormHandler(body);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <h2>Do you own any vehicle?</h2>
          <p className="mt-4">"If yes, please provide your vehicle details."</p>
          <Box margin="24px -16px">
            <Grid
              container
              spacing={3}
              // rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems="center"
            >
              <Grid
                xs={12}
                display="flex"
                gap="12px"
                justifyContent="space-between"
                alignItems="center"
                className="px-4"
              >
                <h6>Do you have any vehicle?</h6>
                <Box className="greenToggle">
                  <ToggleSwitch
                    toggle_status={toggleStatus5}
                    value_update={updateToggleStatus}
                    attrName="attrName5"
                  />
                </Box>
              </Grid>

              {/* Vehicle detail box */}

              {toggleStatus5
                ? addVehicleDetails?.map((vehicle, index) => {
                    return (
                      <>
                        <Box className="gradientDashBox p-0" key={index}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="12px"
                            marginBottom="12px"
                            paddingLeft="16px"
                          >
                            <h6>Which type of vehicle do you own?</h6>
                            <Box display="flex" gap="12px">
                              <CustomCheckbox
                                label="Tw"
                                value={vehicle.vehicleType.value}
                                disabled={false}
                                isChecked={vehicle.vehicleType.value === "Tw"}
                                value_update={() =>
                                  handleCheckboxToggle(index, "Tw")
                                }
                              />
                              <CustomCheckbox
                                label="Car"
                                value={vehicle.vehicleType.value}
                                disabled={false}
                                isChecked={vehicle.vehicleType.value === "Car"}
                                value_update={() =>
                                  handleCheckboxToggle(index, "Car")
                                }
                              />
                              {index >= 1 ? (
                                <>
                                  <CustomButton
                                    text_name="Remove"
                                    class_name="greenSmBtn"
                                    onClickFunction={() => removeVehicle(index)}
                                  />
                                </>
                              ) : null}
                            </Box>
                          </Box>

                          <Grid container spacing={3} className="pb-4">
                            <Grid xs={4} xsOffset={4}>
                              <RKTextField
                                class_name="inputField vehicleNumberField"
                                title={""}
                                value={vehicle.regNo.value}
                                attrName={["addVehicleDetails", "regNo", index]}
                                value_update={updateMasterState}
                                warn_status={vehicle.regNo.warning}
                                error_message={
                                  !isEmpty(vehicle.regNo.value)
                                    ? "Enter Valid Vehicle number"
                                    : "Enter Vehicle number"
                                }
                                placeholder={"DL01ABC1234"}
                                validation_type="ALL_CAPS"
                                max_length={11}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <SearchDropdown
                                class_name="inputField"
                                title="Make & Model"
                                value={vehicle.makeModel.value}
                                attrName={[
                                  "addVehicleDetails",
                                  "makeModel",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                data={vehicle.make_model_data}
                                // data={vehicleDetails.makeModel.value}
                                warn_status={vehicle.makeModel.warning}
                                error_message={"Select make & model"}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <SelectDropdown
                                class_name="inputField"
                                title="Fuel Type"
                                value={vehicle.fuelType.value}
                                attrName={[
                                  "addVehicleDetails",
                                  "fuelType",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                data={vehicle.fuel_type_data}
                                // data={vehicleDetails.fuelType.value}
                                warn_status={vehicle.fuelType.warning}
                                error_message={"Select Fuel Type"}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <SelectDropdown
                                class_name="inputField"
                                title="Variant"
                                value={vehicle.variantCC.value}
                                attrName={[
                                  "addVehicleDetails",
                                  "variantCC",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                data={vehicle.variant_data}
                                warn_status={vehicle.variantCC.warning}
                                error_message={"Select variant"}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <SearchDropdown
                                class_name="inputField"
                                title="Previous Insurer"
                                value={vehicle.previousInsurer.value}
                                attrName={[
                                  "addVehicleDetails",
                                  "previousInsurer",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                data={prevInsList}
                                warn_status={vehicle.previousInsurer.warning}
                                error_message={"Select Previous Insurer"}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <DatePicker
                                class_name="inputField"
                                title={"Registration Date"}
                                value={convertDateToOriginalFormat(
                                  vehicle.registrationDate
                                    .value as unknown as string
                                )}
                                attrName={[
                                  "addVehicleDetails",
                                  "registrationDate",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                error_message="Select Registration Date"
                                warn_status={vehicle.registrationDate.warning}
                                min_date={15 * 12}
                                max_date={3}
                                date_validation_type="MONTHS"
                                default_date={subDays(new Date(), 90)}
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <DatePicker
                                class_name="inputField"
                                title={"Policy Expiry Date"}
                                value={convertDateToOriginalFormat(
                                  vehicle.policyExpiryDate
                                    .value as unknown as string
                                )}
                                attrName={[
                                  "addVehicleDetails",
                                  "policyExpiryDate",
                                  index,
                                ]}
                                value_update={updateMasterState}
                                error_message="Select Policy Expiry Date"
                                warn_status={vehicle.policyExpiryDate.warning}
                                date_validation_type="DAYS"
                                min_date={
                                  calculateAgeInDays(
                                    FORMAT_DD_MM_YYYY(
                                      `${vehicle.registrationDate.value}`
                                    )
                                  ) - 90
                                }
                                max_date={
                                  calculateAgeInDays(
                                    FORMAT_DD_MM_YYYY(`${new Date()}`)
                                  ) - 60
                                }
                              />
                              {/* <p>Policy Expiry Date</p> */}
                            </Grid>
                          </Grid>
                        </Box>

                        {/* <Box
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "12px",
                              padding: "8px",
                              boxShadow: "0px 12px 32px rgba(32,56,100,0.12)",
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                              width: "274px",
                              marginLeft: "auto",
                              marginRight: "16px",
                              marginTop: "16px",
                            }}
                          >
                            <Box
                              sx={{
                                boxShadow: "0px 12px 32px rgba(32,56,100,0.12)",
                                borderRadius: "12px",
                                padding: "8px",
                                fontSize: "10px",
                                cursor: "pointer",
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "8px",
                                width: "104px",
                                minWidth: "104px",
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "12px",
                                  fontSize: "16px",
                                  border: "1px solid #00B587",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "20px",
                                  width: "20px",
                                }}
                                onClick={handleBoxClick1}
                              >
                                +
                              </Box>
                              Add Vehicle
                            </Box>
                            <p style={{ fontSize: "10px", color: "#D9132E" }}>
                              You can add a maximum of three vehicles.
                            </p>
                          </Box> */}
                      </>
                    );
                  })
                : []}
              {toggleStatus5 && addVehicleDetails.length <= 2 ? (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      padding: "8px",
                      boxShadow: "0px 12px 32px rgba(32,56,100,0.12)",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      width: "274px",
                      marginLeft: "auto",
                      marginRight: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        boxShadow: "0px 12px 32px rgba(32,56,100,0.12)",
                        borderRadius: "12px",
                        padding: "8px",
                        fontSize: "10px",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "104px",
                        minWidth: "104px",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "12px",
                          fontSize: "16px",
                          border: "1px solid #00B587",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={handleBoxClick1}
                      >
                        +
                      </Box>
                      Add Vehicle
                    </Box>
                    <p style={{ fontSize: "10px", color: "#D9132E" }}>
                      You can add a maximum of three vehicles.
                    </p>
                  </Box>
                </>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} md={6} textAlign="center" className="mHide">
          <img
            src="./images/insurance-policy.svg"
            width="100%"
            style={{ maxWidth: "492px" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Box className="ctaBtn my-6 bottomButtons" display="flex" gap="24px">
          <Buttons
            onClickFunction={handlePrevious}
            class_name="textMdBtn"
            text_name="Previous"
          />
          <Buttons
            onClickFunction={handleContinue}
            class_name="greenMdBtn roundedBtn"
            text_name="Continue"
            loading={false}
          />
        </Box>
      </Grid>
    </>
  );
};

export default PIRVehicle;
