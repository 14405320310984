import React, { ChangeEvent, useRef, useState } from "react";
import "../Claims.scss";
import TabList from "@mui/lab/TabList";
import { Box, Button, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "../../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import TextArea from "../../../../../Component/Common/FieldTypes/TextArea/TextArea";
import { ClaimUserData } from "../../../../../Type/Claims/ClaimUserData";

const TermClaim = ({
  inputFields,
  setInputFields,
  fieldsUpdateState,
  selectFile,
  fileChange,
  policyDoc,
  handleFileChange,
}: {
  inputFields: ClaimUserData;
  setInputFields: Function;
  fieldsUpdateState: Function;
  selectFile: Function;
  fileChange: Function;
  policyDoc: any;
  handleFileChange: any;
}) => {
  // const handleFileChange = (
  //   refName: string,
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0]; // Access the file object from the event if it exists

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       const base64 = reader.result as string; // The base64 representation of the file
  //       const fileInfo = { name: file.name, url: base64.split("base64,")[1] };

  //       fileChange(refName, fileInfo);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };
  return (
    <>
      <Grid xs={12} className="gridHeading">
        Claim Type
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TabList
            className="tabListButtons"
            onChange={(e, a: "Pending" | "Rejected") => {
              setInputFields({
                ...inputFields,
                termIns: {
                  ...inputFields.termIns,
                  claimType: a,
                },
              });
            }}
          >
            <Tab
              className={
                inputFields.termIns.claimType === "Pending"
                  ? "active"
                  : "inActive"
              }
              label="Pending"
              value="Pending"
            />
            <Tab
              className={
                inputFields.termIns.claimType === "Rejected"
                  ? "active"
                  : "inActive"
              }
              label="Rejected"
              value="Rejected"
            />
          </TabList>
        </Grid>
      </Grid>
      <Box className="termIn mt-6">
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={6} lg={4}>
            <DatePicker
              class_name="inputField"
              title={"Date of Death"}
              value={inputFields.termIns.dateOfDeath.value}
              attrName={["termIns", "dateOfDeath"]}
              value_update={fieldsUpdateState}
              error_message="Enter Date of Death"
              warn_status={inputFields.termIns.dateOfDeath.warning}
              date_validation_type="YEARS"
              min_date={99}
              max_date={1}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4}>
            <DatePicker
              class_name="inputField"
              title={"Date of Submission of Documents"}
              value={inputFields.termIns.dateOfSubmisionOfDoc.value}
              attrName={["termIns", "dateOfSubmisionOfDoc"]}
              value_update={fieldsUpdateState}
              error_message="Enter Date of Submission of Documents"
              warn_status={inputFields.termIns.dateOfSubmisionOfDoc.warning}
              date_validation_type="YEARS"
              min_date={99}
              max_date={1}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="claimPolicyDoc">
              <h5>Policy Document: </h5>
              <Box className="fileUpload file1">
                <input
                  type="file"
                  ref={policyDoc}
                  onChange={(e) => handleFileChange("policyDoc")}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <Button
                  className="cloudButton"
                  variant="contained"
                  onClick={() => selectFile("policyDoc")}
                >
                  <CloudUploadIcon />
                  {inputFields.termIns.policyDoc.docFile?.name
                    ? inputFields.termIns.policyDoc.docFile?.name
                    : "Browse to upload policy doc"}
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        {inputFields.termIns.claimType === "Rejected" ? (
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Reason for rejection"}
                value={inputFields.termIns.reasonForRejection.value}
                attrName={["termIns", "reasonForRejection"]}
                value_update={fieldsUpdateState}
                warn_status={inputFields.termIns.reasonForRejection.warning}
                error_message={" Enter Reason for rejection"}
              />
            </Grid>
          </Grid>
        ) : null}
        {inputFields.termIns.claimType === "Pending" ? (
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Reason for pending"}
                value={inputFields.termIns.reasonForPending.value}
                attrName={["termIns", "reasonForPending"]}
                value_update={fieldsUpdateState}
                warn_status={inputFields.termIns.reasonForPending.warning}
                error_message={" Enter Reason for pending"}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={3}>
          <Grid xs={12}>
            <TextArea
              class_name="inputField"
              title={"What do you expect us to do?"}
              value={inputFields.termIns.whatDoYouExpect.value}
              attrName={["termIns", "whatDoYouExpect"]}
              value_update={fieldsUpdateState}
              warn_status={inputFields.termIns.whatDoYouExpect.warning}
              error_message=" Enter What do you expect us to do?"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TermClaim;
