import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../Store/hooks";
import "./../../../Component/Common/FieldTypes/FieldTypes.scss";
import "./../../../index.scss";
import "./MNavbar.scss";
import MenuPopup from "./MenuPopup/MenuPopup";

const MNavbar = () => {
  const { header } = useAppSelector((state) => state.Home);
  const [slideOpen, setSlideOpen] = useState(false);
  const navigate = useNavigate();

  const togglePanel = () => {
    setSlideOpen(!slideOpen);
  };
  if (slideOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  return (
    <Box className="navbar_m">
      <MenuPopup open={slideOpen} setOpen={setSlideOpen} />
      {/* <Grid container spacing={3} paddingTop={"12px"}>
        <Grid xs={12} className="top-strip">
          <p dangerouslySetInnerHTML={{ __html: header.top_heading }} />
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={3}
        paddingTop={"8px"}
        paddingBottom={"8px"}
        alignItems={"center"}
      >
        <Grid xs={7} className="logo-section">
          <Link onClick={() => navigate("/")}>
            <img src="/images/logo.svg" alt="bima-street" className="logo" />
          </Link>
        </Grid>
        <Grid xs={5} textAlign={"right"} alignSelf={"center"}>
          <Link className="menuIcon" onClick={togglePanel}>
            <img src="./images/menu_icon.svg" alt="menu-icon" />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNavbar;
