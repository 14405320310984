import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import { useAppSelector } from "../../../../../Store/hooks";
import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";

function MAboutUS() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 959, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 463, min: 250 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const about_us = useAppSelector(
    (state) => state.CMS.about_us.data.attributes
  );

  return (
    <Box>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <p className="desc_p">{about_us.desc}</p>
        </Grid>
        <Grid xs={12} textAlign={"center"}>
          <img
            className="illustration-icon"
            alt=""
            src={
              STRAPI_DOMAIN + about_us.primary_info.image.data.attributes.url
            }
          />
        </Grid>
        <Grid xs={12}>
          <h4 style={{ marginBottom: "20px" }}>
            {about_us.primary_info.title}
          </h4>
          <p className="desc_p ">{about_us.primary_info.desc}</p>
        </Grid>
        <Grid xs={12}>
          <div className="instance-parent">
            {about_us.primary_info_data.map((data, index) => (
              <div className="frame-wrapper">
                <div className="frame-parent1">
                  <div className="frame-wrapper1">
                    <img
                      alt=""
                      src={STRAPI_DOMAIN + data.image.data.attributes.url}
                    />
                  </div>
                  <div className="committed-to-the-objective-of-parent">
                    <div className="committed-to-the">{data.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0} rowSpacing={0} marginBottom={"40px"}>
        <Grid xs={12} className="expertise-section">
          <h4>{about_us.expertise.title}</h4>
          <p>{about_us.expertise.desc}</p>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
          >
            {about_us.expertise_data.map((data, index) => (
              <div key={index}>
                <div className="productCard">
                  <div className="inner">
                    <img
                      src={STRAPI_DOMAIN + data.image.data.attributes.url}
                      alt={data.title}
                    />
                    <h4>{data.title}</h4>
                    <p>{data.desc}</p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        marginBottom={"40px"}
        gap={"20px"}
      >
        <Grid xs={12} textAlign={"center"}>
          <h4>{about_us.mission_statement_title}</h4>
          <img
            className="vision_mission_img"
            alt=""
            src={STRAPI_DOMAIN + about_us.mission_image.data.attributes.url}
          />
        </Grid>
        <Grid xs={12}>
          <h5 style={{ marginBottom: "10px" }}>
            {about_us.vision_statement.title}
          </h5>
          <p className="desc_p">{about_us.vision_statement.desc}</p>
        </Grid>
        <Grid xs={12}>
          <h5 style={{ marginBottom: "10px" }}>{about_us.mission_title}</h5>
          <ul className="ms_points_list">
            {about_us.mission_statement_data.map((data, index) => (
              <li>
                <img alt="" src="./images/list-tick-icon.svg" />
                <p>{data.title}</p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        marginBottom={"40px"}
        gap={"20px"}
      >
        <Grid xs={12} textAlign={"center"}>
          <h4>{about_us.ethics_title}</h4>
        </Grid>
        <Grid xs={12}>
          <ul className="ethic_point_list">
            {about_us.ethics_data.map((data, index) => (
              <li>
                <span>{index + 1}.</span>
                <p>{data.title}</p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        marginBottom={"40px"}
        gap={"20px"}
      >
        <Grid xs={12}>
          <h4 style={{ marginBottom: "10px" }}>{about_us.quality.title}</h4>
          <p className="desc_p">{about_us.quality.desc}</p>
        </Grid>
        <Grid xs={12}>
          <h5 style={{ marginBottom: "10px" }}>
            {about_us.quality_data_title}
          </h5>
          {about_us.quality_data.map((data, index) => (
            <>
              <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                <img
                  alt=""
                  src={STRAPI_DOMAIN + data.image.data.attributes.url}
                  width={120}
                />
                <p className="desc_p">{data.title}</p>
              </Box>
              <hr />
            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default MAboutUS;
