import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import { Box, Tab } from "@mui/material";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import "./MAbout.scss";
import MAboutUS from "./MAboutUS/MAboutUS";
import MKeyManagTool from "./MKeyManagTool/MKeyManagTool";
import MMDCEO from "./MMDCEO/MMDCEO";
import { useAppSelector } from "../../../../Store/hooks";

export default function MAbout() {
  const { about_us, key_management_personal, md_ceo } = useAppSelector(
    (state) => state.CMS
  );
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MNavbar />
      <Box className="aboutusWrapper_m">
        <TabContext value={value}>
          <Box className="pageHeader">
            {value === "1"
              ? about_us.data.attributes.title
              : value === "2"
              ? key_management_personal.title
              : md_ceo.data.attributes.title}
          </Box>
          <Box className="sectionTabs">
            <TabList onChange={handleChange}>
              <Tab label={about_us.data.attributes.title} value="1" />
              <Tab label={key_management_personal.title} value="2" />
              <Tab label={md_ceo.data.attributes.title} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <MAboutUS />
          </TabPanel>
          <TabPanel value="2">
            <MKeyManagTool />
          </TabPanel>
          <TabPanel value="3">
            <MMDCEO />
          </TabPanel>
        </TabContext>
      </Box>
      <MFooter />
    </>
  );
}
