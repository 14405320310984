import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import { default as Buttons } from "../../../Component/Common/CustomButton/CustomButton";
import "./PIR.scss";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";

import { TDropdown } from "../../../Type/Common/TDropdown";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/CAR/CarAddFormServices";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { toast } from "react-toastify";
import { PIR_FORM_SERVICES } from "../../../Services/PIR/PirService";
import {
  PIR_DATA,
  PIR_DATA_SUMINSURED,
  PIR_DATA_SUMINSURED_TERM,
  PIR_STEP,
} from "../../../Store/Slice_Reducer/PIR/PirSlice";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import PIRPersonal from "./PIRPersonal/PIRPersonal";
import PIRInsuranceFor from "./PIRInsuranceFor/PIRInsuranceFor";
import PIRFinancial from "./PIRFinancial/PIRFinancial";
import PIRInsurancePolicy from "./PIRInsurancePolicy/PIRInsurancePolicy";
import PIRProperty from "./PIRProperty/PIRProperty";
import PIRVehicle from "./PIRVehicle/PIRVehicle";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MContactUs from "../../Mobile/CMS/MContactUs/MContactUs";
import Footer from "../../../Component/Desktop/Footer/Footer";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
interface InsuredForState {
  insuredMember: {
    value: string[];
    warning: boolean;
  };
}
function PIR() {
  const isMobile = useIsMobile();
  const { PIR } = useAppSelector((state: any) => state);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  type TAddVehicle = {
    haveAnyVehicle: { value: string; warning?: boolean };
    makeModel: { value: string | any; warning: boolean };
    make_model_data: TDropdown[];
    fuel_type_data: TDropdown[];
    variant_data: TDropdown[];
    regNo: { value: string; warning: boolean };
    fuelType: { value: string | any; warning: boolean };
    variantCC: { value: string | any; warning: boolean };
    previousInsurer: { value: string | any; warning: boolean };
    registrationDate: { value: string | Date; warning: boolean };
    policyExpiryDate: { value: string | Date; warning: boolean };
    vehicleType: { value: "Car" | "Tw" | ""; warning: boolean };
  };

  const [haveVehicle, setHaveVehicle] = useState<{
    haveAnyVehicle: { value: string; warning: boolean };
  }>({
    haveAnyVehicle: { value: "No", warning: false },
  });
  const [addVehicleDetails, setaddVehicleDetails] = useState<TAddVehicle[]>([
    {
      haveAnyVehicle: { value: "No", warning: false },
      makeModel: { value: "", warning: false },
      fuelType: { value: "", warning: false },
      variantCC: { value: "", warning: false },
      registrationDate: { value: "", warning: false },
      policyExpiryDate: { value: "", warning: false },
      vehicleType: {
        value: haveVehicle.haveAnyVehicle.value === "Yes" ? "Tw" : "",
        warning: false,
      },
      previousInsurer: { value: "", warning: false },
      regNo: { value: "", warning: false },
      fuel_type_data: [],
      make_model_data: [],
      variant_data: [],
    },
  ]);

  const [step, setStep] = useState(0);

  const dispatch: any = useAppDispatch();

  const incrementStep = () => {
    setStep((prev) => prev + 1);
    dispatch(PIR_STEP(step + 1));
  };
  const decrementStep = () => {
    setStep((prev) => prev - 1);
    dispatch(PIR_STEP(step - 1));
  };
  useEffect(() => {
    console.log("inside the useeffect", PIR.step);
    setStep(PIR.step);
    setLoading(false);
  }, [PIR.step]);
  const submitFormHandler = (body: any) => {
    const filteredVehicleDetails = addVehicleDetails?.map((data) => {
      const {
        fuel_type_data,
        make_model_data,
        variant_data,
        haveAnyVehicle,
        ...otherData
      } = data;
      return otherData;
    });
    let dto: any = {};
    let keyss = Object.keys(PIR.PERSONAL);
    const p2: any = { ...PIR.PERSONAL };
    for (let value of keyss) {
      dto[value] = p2[value]?.value;
    }
    let key2 = Object.keys(PIR.RELATION);
    const i2: any = { ...PIR.RELATION };
    for (let value of key2) {
      dto[value] = i2[value]?.value;
    }
    let key3 = Object.keys(PIR.INSURANCEPOLICY);
    const i3: any = { ...PIR.INSURANCEPOLICY };
    for (let value of key3) {
      dto[value] = i3[value]?.value;
    }
    // let key4 = Object.keys(PIR.FINANCIAL);
    // const f2: any = { ...PIR.FINANCIAL };
    // for (let value of key4) {
    //   dto[value] = f2[value]?.value;
    // }

    let key5 = Object.keys(PIR.OWNHOUSE);
    const o2: any = PIR.OWNHOUSE;
    for (let value of key5) {
      dto[value] = o2[value]?.value;
    }

    let key6 = Object.keys(body.HAVEANYVEHICLE);
    const h2: any = body.HAVEANYVEHICLE;
    for (let value of key6) {
      dto[value] = h2[value]?.value;
    }

    let vehicle: Array<any> = [];
    for (const data of body.HAVEVEHICLE) {
      let single_vehicle_data = {};
      Object.entries(data).map(([key, value], index) => {
        single_vehicle_data = {
          ...single_vehicle_data,
          [key]: (value as any)?.value,
        };
      });
      vehicle.push(single_vehicle_data);
    }

    dto = { ...dto, vehicles: vehicle };
    console.log("dt00000=====>", dto);
    // dispatch(PIR_FORM_SERVICES.PIR_FORM(onSuccess, onError, body));
    if (dto) {
      const onSuccess = (res: any) => {
        const result = res.sumInsured;
        const termResult = res.termSumInsured;
        dispatch(PIR_DATA_SUMINSURED(result));
        dispatch(PIR_DATA_SUMINSURED_TERM(termResult));
      };
      const onError = () => {
        console.log("onError");
      };

      PIR_FORM_SERVICES.PIR_FORM(onSuccess, onError, dto);
      navigate(COMMON_ROUTES.PIR_REPORT);
    }
  };

  return (
    <>
      <Box
        className="pirWrapper"
        sx={{
          background: "linear-gradient(180deg, #ffffff 0%, #EDF4FD 100%)",
          minHeight: "100vh",
        }}
      >
        {isMobile ? <MNavbar /> : <Navbar />}
        {loading && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                height: "100vh",
                width: "100%",
              }}
            >
              <CircularProgress />
              <p>Please wait...</p>
            </Box>
          </>
        )}
        {step === 0 && !loading && (
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <h2>Let's start your insurance planning!</h2>
              <p className="mt-4">
                Absolutely! By understanding your unique needs and financial
                landscape, we'll craft a personalized insurance blueprint
                designed to secure your future. From pinpointing the ideal
                coverage to customized plans and expert tax guidance, it's all
                about empowering you with comprehensive solutions, effortlessly
                accessible with just a few clicks!
              </p>
              <Box className="ctaBtn mt-6">
                <Buttons
                  onClickFunction={() => {
                    setStep(1);
                  }}
                  class_name="greenMdBtn roundedBtn"
                  text_name="Start Now"
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <img src="./images/lets-start.svg" width="100%" alt="img" />
            </Grid>
          </Grid>
        )}

        {step === 1 && (
          <PIRPersonal
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}

        {step === 2 && (
          <PIRInsuranceFor
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}

        {/* {step === 3 && (
        <PIRFinancial
          incrementStep={incrementStep}
          decrementStep={decrementStep}
        />
      )} */}

        {step === 3 && (
          <PIRInsurancePolicy
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}

        {step === 4 && (
          <PIRProperty
            incrementStep={incrementStep}
            decrementStep={decrementStep}
          />
        )}

        {step === 5 && (
          <PIRVehicle
            incrementStep={incrementStep}
            decrementStep={decrementStep}
            submitFormHandler={submitFormHandler}
          />
        )}
      </Box>
      <Box sx={{ ".Footer": { marginTop: "0px!important" } }}>
        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </>
  );
}

export default PIR;
