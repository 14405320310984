import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { PIR_FORM_DTO } from "../../DTO/PIRDTO/PirDTO";
import { NODE_DOMAIN } from "../../URLCollection/Domain";
import { PirUrls } from "../../URLCollection/PIR/PirUrsls";

const PIR_FORM = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: PIR_FORM_DTO
) => {
  PostAPI.call(
    PirUrls.FORM,
    // "https://nodeapi.bimastreet.com/api/v1/pera/user",
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

export const PIR_FORM_SERVICES = {
  PIR_FORM,
};
