import React from "react";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import { useAppSelector } from "../../../../Store/hooks";
import "./About.scss";
import AboutUS from "./AboutUS/AboutUS";
import KeyManagTool from "./KeyManagTool/KeyManagTool";
import MDCEO from "./MDCEO/MDCEO";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function About() {
  const [value, setValue] = React.useState("1");
  const { key_management_personal, md_ceo, about_us } = useAppSelector(
    (state) => state.CMS
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Navbar />
      <Box className="aboutusWrapper">
        <TabContext value={value}>
          <Box className="pageHeader">
            {value === "1"
              ? about_us?.data?.attributes?.title
              : value === "2"
              ? key_management_personal?.title
              : md_ceo?.data?.attributes?.title}
          </Box>
          <Box className="sectionTabs">
            <TabList onChange={handleChange}>
              <Tab label={about_us?.data?.attributes?.title} value="1" />
              <Tab label={key_management_personal?.title} value="2" />
              <Tab label={md_ceo?.data?.attributes?.title} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <AboutUS />
          </TabPanel>
          <TabPanel value="2">
            <KeyManagTool />
          </TabPanel>
          <TabPanel value="3">
            <MDCEO />
          </TabPanel>
        </TabContext>
      </Box>
      <Footer />
    </>
  );
}
