import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import LoginContainer from "../../../../Container/MyAccount/LoginContainer/LoginContainer";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { MY_ACCOUNT_ROUTES } from "../../../../Router/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import "./../../../../CommonSCSS/ModalPopup.scss";
interface Props {
  open: boolean;
  setOpen: any;
}

const MenuPopup = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    navigate("/");
  };

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open}
        size={100}
        panelClassName="slidingPanel"
        backdropClicked={() => setOpen(false)}>
        <>
          <Box
            sx={{
              background: "#fff",
              position: "fixed",
              width: "100%",
              left: "0px",
              top: "0px",
              paddingTop: "8px",
              paddingBottom: "8px",
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
              zIndex: 9,
            }}>
            <Grid container spacing={3} alignItems="center">
              <Grid xs={4}>
                <Link
                  href="javascript:void(0);"
                  className="close-button"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
              <Grid xs={8} textAlign={"right"} className="ctaBtn">
                {USER_DATA.mobile.value ? (
                  <>
                    <Button
                      className="greenMdBtn rounded mr-3"
                      id="basic-button"
                      onClick={(e) => {
                        window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                      }}>
                      Hi! User
                    </Button>
                    <Button
                      variant="contained"
                      className="greenMdBtn"
                      onClick={() => {
                        handleLogout();
                        setOpen(false);
                      }}>
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    className="greenMdBtn"
                    onClick={() => {
                      setOpenCustomerLogin(true);
                      setOpen(false);
                    }}>
                    Login
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className="menupopup">
            <Grid container spacing={3} className="mt-1">
              {USER_DATA.mobile.value && (
                <Grid xs={12} textAlign={"right"}>
                  <h5>Account</h5>
                  <ul className="nav-section">
                    <li>
                      <Link
                        className="nav-link"
                        onClick={() => {
                          setOpen(false);
                          window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                        }}>
                        My Dashboard
                      </Link>
                    </li>
                  </ul>
                </Grid>
              )}
              <Grid xs={12} textAlign={"right"}>
                <h5>COMPANY</h5>
                <ul className="nav-section">
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.ABOUT_US);
                      }}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PRIVACY_POLICY);
                      }}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                      }}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION);
                      }}>
                      Cancellation & Refund
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.DISCLAIMER);
                      }}>
                      Disclaimer
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs={12} textAlign={"right"}>
                <hr />
              </Grid>
              <Grid xs={12} textAlign={"right"}>
                <h5>PRODUCTS</h5>
                <ul className="nav-section">
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                      }}>
                      Health Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PRODUCT_CAR);
                      }}>
                      Car Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PRODUCT_TW);
                      }}>
                      Two-Wheeler Insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PRODUCT_TERM);
                      }}>
                      Term Insurance
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs={12} textAlign={"right"}>
                <hr />
              </Grid>
              <Grid xs={12} textAlign={"right"}>
                <h5>SERVICES</h5>
                <ul className="nav-section">
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.CONTACT_US);
                      }}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.CARRIER);
                      }}>
                      Carrers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.NEED_HELP);
                      }}>
                      Need Help?
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="pos-link"
                      onClick={() => {
                        window.open("https://pos.bimastreet.com/", "_blank");
                      }}>
                      POS
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="ca-link"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.CLAIMS);
                      }}>
                      Claim Assistance
                      <span className="boxblink">
                        <p className="blink_text">100% Free</p>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="pir-link mt-6"
                      onClick={() => {
                        setOpen(false);
                        navigate(COMMON_ROUTES.PIR);
                      }}>
                      Personalised Insurance
                      <span className="boxblink">
                        <p className="blink_text">Free Report</p>
                      </span>
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </>
      </SlidingPanel>
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
    </>
  );
};

export default MenuPopup;
