import "../Claims.scss";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "../../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import CustomButton from "../../../../../Component/Common/CustomButton/CustomButton";
import SelectDropdown from "../../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import TextArea from "../../../../../Component/Common/FieldTypes/TextArea/TextArea";
import {
  ClaimUserData,
  insurerCompanies,
  typesOfClaimStatus,
  typesOfDrivingLicense,
} from "../../../../../Type/Claims/ClaimUserData";
import CustomDateTimePicker from "../../../../../Component/Common/FieldTypes/DateTimePicker/DateTimePicker";
import { ChangeEvent } from "react";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

const TwoWheelerClaim = ({
  inputFields,
  setInputFields,
  fieldsUpdateState,
  selectFile,
  fileChange,
  multiFileChange,
  removePhoto,
  TWcopyOfRCOwn,
  TWcopyOfDrivingLicenseOwn,
  TWcopyOfComplaintOwn,
  TWcopyOfPolicyDocOwn,
  TWaccidentPhotosOwn,
  TWcopyOfRCTheft,
  TWcopyOfPolicyDocTheft,
  TWcopyOfComplaintTheft,
  TWcopyOfFinalReportTheft,
  TWcopyOfRCThird,
  TWcopyOfDrivingLicenseThird,
  TWcopyOfComplaintThird,
  TWcopyOfPolicyDocThird,
  TWaccidentPhotosThird,
  handleFileChange,
}: {
  inputFields: ClaimUserData;
  setInputFields: Function;
  fieldsUpdateState: Function;
  selectFile: Function;
  fileChange: Function;
  multiFileChange: Function;
  removePhoto: Function;
  TWcopyOfRCOwn: any;
  TWcopyOfDrivingLicenseOwn: any;
  TWcopyOfComplaintOwn: any;
  TWcopyOfPolicyDocOwn: any;
  TWaccidentPhotosOwn: any;
  TWcopyOfRCTheft: any;
  TWcopyOfPolicyDocTheft: any;
  TWcopyOfComplaintTheft: any;
  TWcopyOfFinalReportTheft: any;
  TWcopyOfRCThird: any;
  TWcopyOfDrivingLicenseThird: any;
  TWcopyOfComplaintThird: any;
  TWcopyOfPolicyDocThird: any;
  TWaccidentPhotosThird: any;
  handleFileChange: any;
}) => {
  // const handleFileChange = (
  //   refName: string,
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0]; // Access the file object from the event if it exists

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       const base64 = reader.result as string; // The base64 representation of the file
  //       const fileInfo: { name: string; url: string } = {
  //         name: file.name,
  //         url: base64.split("base64,")[1],
  //       };

  //       fileChange(refName, fileInfo);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  return (
    <>
      {/* TW Insurance */}
      <Grid sm={12} className="gridHeading">
        Claim Type
      </Grid>
      <Grid sm={12} md={12} lg={12} padding="0px">
        <TabContext value={inputFields.TwIns.claimType}>
          <Box>
            <TabList
              className="tabListButtons"
              onChange={(e, a: "OwnDamage" | "Theft" | "ThirdParty") => {
                setInputFields({
                  ...inputFields,
                  TwIns: {
                    ...inputFields.TwIns,
                    claimType: a,
                  },
                });
              }}>
              <Tab
                className={
                  inputFields.TwIns.claimType === "Own Damage Claim"
                    ? "active"
                    : "inActive"
                }
                label="Own Damage Claim"
                value="Own Damage Claim"
              />
              <Tab
                className={
                  inputFields.TwIns.claimType === "Theft Claim"
                    ? "active"
                    : "inActive"
                }
                label="Theft Claim"
                value="Theft Claim"
              />
              <Tab
                className={
                  inputFields.TwIns.claimType === "Third Party Claim"
                    ? "active"
                    : "inActive"
                }
                label="Third Party Claim"
                value="Third Party Claim"
              />
            </TabList>
          </Box>
          {/* Own damage claim */}
          <TabPanel
            className="tabPanel ownDamage"
            value="Own Damage Claim"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid sm={12} className="gridHeading ownHead">
              Own Damage Claim Requirements
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} md={3} lg={3}>
                <RKTextField
                  class_name="inputField vehicleNumberField"
                  title={""}
                  value={inputFields.TwIns.OwnDamage.vehicleNumber.value}
                  attrName={["TwIns", "OwnDamage", "vehicleNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.vehicleNumber.warning
                  }
                  error_message={
                    isEmpty(inputFields.TwIns.OwnDamage.vehicleNumber.value)
                      ? "Enter vehicle number"
                      : "Enter valid vehicle number"
                  }
                  placeholder={"DL01A2345"}
                  validation_type="ALL_CAPS"
                  max_length={11}
                />
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={9}
                lg={9}
                sx={{ display: "flex", alignItems: "center" }}>
                <div className="fileUpload">
                  <input
                    type="file"
                    ref={TWcopyOfRCOwn}
                    onChange={(e) => handleFileChange("copyOfRC")}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfRC")}>
                    <CloudUploadIcon />
                    {inputFields.TwIns.OwnDamage.copyOfRC.docFile?.name
                      ? inputFields.TwIns.OwnDamage.copyOfRC.docFile?.name
                      : "Browse to upload copy of RC"}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <CustomDateTimePicker
                  class_name="inputField"
                  title={"Date & Time of Accident"}
                  value={inputFields.TwIns.OwnDamage.dateTimeOfAccident.value}
                  attrName={["TwIns", "OwnDamage", "dateTimeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.dateTimeOfAccident.warning
                  }
                  error_message={" Enter Date & Time of Accident"}
                  date_validation_type="MONTHS"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Place of Accident"}
                  value={inputFields.TwIns.OwnDamage.placeOfAccident.value}
                  attrName={["TwIns", "OwnDamage", "placeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.placeOfAccident.warning
                  }
                  error_message={" Enter Place of Accident"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Driver at the Time of Accident"}
                  value={
                    inputFields.TwIns.OwnDamage.driverAtTimeOfAccident.value
                  }
                  attrName={["TwIns", "OwnDamage", "driverAtTimeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.driverAtTimeOfAccident.warning
                  }
                  error_message={" Enter Driver at the Time of Accident"}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Driving Licence no."}
                  value={inputFields.TwIns.OwnDamage.drivingLicenseNo.value}
                  attrName={["TwIns", "OwnDamage", "drivingLicenseNo"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.drivingLicenseNo.warning
                  }
                  error_message={" Enter Driving Licence no."}
                  max_length={20}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  class_name="inputField"
                  title={"Driving Licence valid upto"}
                  value={
                    inputFields.TwIns.OwnDamage.drivingLicenseValidUpto.value
                  }
                  attrName={["TwIns", "OwnDamage", "drivingLicenseValidUpto"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Driving Licence valid upto"
                  warn_status={
                    inputFields.TwIns.OwnDamage.drivingLicenseValidUpto.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Type of licence"}
                  value={inputFields.TwIns.OwnDamage.typeOfDrivingLicense.value}
                  attrName={["TwIns", "OwnDamage", "typeOfDrivingLicense"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.typeOfDrivingLicense.warning
                  }
                  error_message={" Enter Type of licence"}
                  data={typesOfDrivingLicense}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={8}
                sx={{ display: "flex", alignItems: "center" }}>
                <Box className="fileUpload file1">
                  <input
                    type="file"
                    ref={TWcopyOfDrivingLicenseOwn}
                    onChange={(e) => handleFileChange("copyOfDrivingLicense")}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfDrivingLicense")}>
                    <CloudUploadIcon />
                    {inputFields.TwIns.OwnDamage.copyOfDrivingLicence.docFile
                      ?.name
                      ? inputFields.TwIns.OwnDamage.copyOfDrivingLicence.docFile
                          ?.name
                      : "Browse to upload copy of Driving License"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <div className="claimPolicyDoc">
                  <h5>Reported at Police Station?</h5>
                  <Box sx={{ padding: "0 !important" }}>
                    <CustomButton
                      text_name={"Yes"}
                      class_name={`greenMdBtn mr-4 ${
                        inputFields.TwIns.OwnDamage.reportedAtPoliceStation
                          ? "yes"
                          : "nope"
                      }`}
                      onClickFunction={() => {
                        setInputFields({
                          ...inputFields,
                          TwIns: {
                            ...inputFields.TwIns,
                            OwnDamage: {
                              ...inputFields.TwIns.OwnDamage,
                              reportedAtPoliceStation: true,
                            },
                          },
                        });
                      }}
                    />
                    <CustomButton
                      text_name={"No"}
                      class_name={`greenMdBtn ${
                        inputFields.TwIns.OwnDamage.reportedAtPoliceStation
                          ? "nope"
                          : "yes"
                      }`}
                      onClickFunction={() => {
                        setInputFields({
                          ...inputFields,
                          TwIns: {
                            ...inputFields.TwIns,
                            OwnDamage: {
                              ...inputFields.TwIns.OwnDamage,
                              reportedAtPoliceStation: false,
                            },
                          },
                        });
                      }}
                    />
                  </Box>
                </div>
              </Grid>
              {inputFields.TwIns.OwnDamage.reportedAtPoliceStation ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", alignItems: "center" }}>
                  <div className="claimPolicyDoc">
                    <Box className="fileUpload">
                      <input
                        type="file"
                        ref={TWcopyOfComplaintOwn}
                        onChange={(e) => handleFileChange("copyOfComplaint")}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("copyOfComplaint")}>
                        <CloudUploadIcon />
                        {inputFields.TwIns.OwnDamage.copyOfComplaint.value
                          ? inputFields.TwIns.OwnDamage.copyOfComplaint.value
                          : "Browse to upload copy of complaint"}
                      </Button>
                    </Box>
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Select Insurer"
                  value={inputFields.TwIns.OwnDamage.insurer.value}
                  attrName={["TwIns", "OwnDamage", "insurer"]}
                  value_update={fieldsUpdateState}
                  data={insurerCompanies}
                  warn_status={inputFields.TwIns.OwnDamage.insurer.warning}
                  error_message="Select Insurer"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <RKTextField
                  class_name="inputField"
                  title={"Policy no."}
                  value={inputFields.TwIns.OwnDamage.policyNumber.value}
                  attrName={["TwIns", "OwnDamage", "policyNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={inputFields.TwIns.OwnDamage.policyNumber.warning}
                  error_message={" Enter Policy no."}
                  max_length={20}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy Start Date"}
                  value={inputFields.TwIns.OwnDamage.policyStartDate.value}
                  attrName={["TwIns", "OwnDamage", "policyStartDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy Start Date"
                  warn_status={
                    inputFields.TwIns.OwnDamage.policyStartDate.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy End Date"}
                  value={inputFields.TwIns.OwnDamage.policyEndDate.value}
                  attrName={["TwIns", "OwnDamage", "policyEndDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy End Date"
                  warn_status={
                    inputFields.TwIns.OwnDamage.policyEndDate.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>Policy Document:</h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={TWcopyOfPolicyDocOwn}
                      onChange={(e) => handleFileChange("copyOfPolicyDoc")}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPolicyDoc")}>
                      <CloudUploadIcon />
                      {inputFields.TwIns.OwnDamage.copyOfPolicyDoc.docFile?.name
                        ? inputFields.TwIns.OwnDamage.copyOfPolicyDoc.docFile
                            ?.name
                        : "Browse to upload Policy Document"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <div className="claimPolicyDoc1">
                  <h5>Accident Photos/Images, if any</h5>
                  <>
                    <input
                      type="file"
                      ref={TWaccidentPhotosOwn}
                      style={{ display: "none" }}
                      onChange={() => multiFileChange("accidentPhotos")}
                      accept="image/*"
                      multiple
                    />
                    <span onClick={() => selectFile("accidentPhotos")}>
                      {" "}
                      + Add More{" "}
                    </span>
                  </>
                </div>
              </Grid>
            </Grid>

            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                {inputFields.TwIns.OwnDamage.accidentPhotos?.length > 0 ? (
                  inputFields?.TwIns?.OwnDamage?.accidentPhotos?.map(
                    (item, i) => (
                      <div className="claimPolicyDoc1">
                        <Box className="fileUpload file1" padding="0px">
                          <Button className="cloudButton" variant="contained">
                            <CloudUploadIcon />
                            {item?.name}
                          </Button>
                        </Box>
                        <Box>
                          <Button onClick={() => removePhoto(i)}>Remove</Button>
                        </Box>
                      </div>
                    )
                  )
                ) : (
                  <div className="claimPolicyDoc">
                    <Box className="fileUpload file1" padding="0px">
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("accidentPhotos")}>
                        <CloudUploadIcon />
                        Browse to upload the images
                      </Button>
                    </Box>
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Brief Description about accident"}
                  value={
                    inputFields.TwIns.OwnDamage.descriptionAboutAccident.value
                  }
                  attrName={["TwIns", "OwnDamage", "descriptionAboutAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.descriptionAboutAccident.warning
                  }
                  error_message=" Enter Brief Description about accident"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Claim Status"
                  value={inputFields.TwIns.OwnDamage.claimStatus.value}
                  attrName={["TwIns", "OwnDamage", "claimStatus"]}
                  value_update={fieldsUpdateState}
                  data={typesOfClaimStatus}
                  warn_status={inputFields.TwIns.OwnDamage.claimStatus.warning}
                  error_message=" Select Claim Status"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={inputFields.TwIns.OwnDamage.whatDoYouExpect.value}
                  attrName={["TwIns", "OwnDamage", "whatDoYouExpect"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.OwnDamage.whatDoYouExpect.warning
                  }
                  error_message=" Enter What do you expect us to do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
          {/* Theft Claim */}
          <TabPanel
            className="tabPanel ownDamage"
            value="Theft Claim"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid xs={12} className="gridHeading ownHead">
              Theft Claim Requirements
            </Grid>
            <Grid container spacing={3}>
              {/* <Grid container  gap="20px"> */}
              <Grid xs={12} sm={6} md={3} lg={3}>
                <RKTextField
                  class_name="inputField vehicleNumberField"
                  title={""}
                  value={inputFields.TwIns.Theft.vehicleNumber.value}
                  attrName={["TwIns", "Theft", "vehicleNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={inputFields.TwIns.Theft.vehicleNumber.warning}
                  error_message={
                    isEmpty(inputFields.TwIns.Theft.vehicleNumber.value)
                      ? "Enter vehicle number"
                      : "Enter valid vehicle number"
                  }
                  placeholder={"DL01A2345"}
                  validation_type="ALL_CAPS"
                  max_length={11}
                />
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={9}
                lg={9}
                sx={{ display: "flex", alignItems: "center" }}>
                <div className="fileUpload">
                  <input
                    type="file"
                    ref={TWcopyOfRCTheft}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange("copyOfRC")}
                    accept="image/*"
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfRC")}>
                    <CloudUploadIcon />
                    {inputFields.TwIns.Theft.copyOfRC.docFile?.name
                      ? inputFields.TwIns.Theft.copyOfRC.docFile?.name
                      : "Browse to upload copy of RC"}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  class_name="inputField"
                  title={"Date of Loss noticed"}
                  value={inputFields.TwIns.Theft.dateOfLossNoticed.value}
                  attrName={["TwIns", "Theft", "dateOfLossNoticed"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.Theft.dateOfLossNoticed.warning
                  }
                  error_message={" Enter Date of Loss noticed"}
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Place of Accident"}
                  value={inputFields.TwIns.Theft.placeOfAccident.value}
                  attrName={["TwIns", "Theft", "placeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={inputFields.TwIns.Theft.placeOfAccident.warning}
                  error_message={" Enter Place of Accident"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Select Insurer"
                  value={inputFields.TwIns.Theft.insurer.value}
                  attrName={["TwIns", "Theft", "insurer"]}
                  value_update={fieldsUpdateState}
                  data={insurerCompanies}
                  warn_status={inputFields.TwIns.Theft.insurer.warning}
                  error_message="Select Insurer"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <RKTextField
                  class_name="inputField"
                  title={"Policy no."}
                  value={inputFields.TwIns.Theft.policyNumber.value}
                  attrName={["TwIns", "Theft", "policyNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={inputFields.TwIns.Theft.policyNumber.warning}
                  error_message={" Enter Policy no."}
                  max_length={20}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy Start Date"}
                  value={inputFields.TwIns.Theft.policyStartDate.value}
                  attrName={["TwIns", "Theft", "policyStartDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy Start Date"
                  warn_status={inputFields.TwIns.Theft.policyStartDate.warning}
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy End Date"}
                  value={inputFields.TwIns.Theft.policyEndDate.value}
                  attrName={["TwIns", "Theft", "policyEndDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy End Date"
                  warn_status={inputFields.TwIns.Theft.policyEndDate.warning}
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>Policy Document:</h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={TWcopyOfPolicyDocTheft}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange("copyOfPolicyDoc")}
                      accept="image/*"
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPolicyDoc")}>
                      <CloudUploadIcon />
                      {inputFields.TwIns.Theft.copyOfPolicyDoc.docFile?.name
                        ? inputFields.TwIns.Theft.copyOfPolicyDoc.docFile?.name
                        : "Browse to upload of Policy Document"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>FIR Copy:</h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={TWcopyOfComplaintTheft}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange("copyOfComplaint")}
                      accept="image/*"
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfComplaint")}>
                      <CloudUploadIcon />
                      {inputFields.TwIns.Theft.copyOfComplaint.docFile?.name
                        ? inputFields.TwIns.Theft.copyOfComplaint.docFile?.name
                        : "Browse to upload copy of Complaint"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>Finaly report from police station:</h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={TWcopyOfFinalReportTheft}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange("copyOfFinalReport")}
                      accept="image/*"
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfFinalReport")}>
                      <CloudUploadIcon />
                      {inputFields.TwIns.Theft.copyOfFinalReport.docFile?.name
                        ? inputFields.TwIns.Theft.copyOfFinalReport.docFile
                            ?.name
                        : "Browse to upload the report"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Brief Description about accident"}
                  value={inputFields.TwIns.Theft.descriptionAboutAccident.value}
                  attrName={["TwIns", "Theft", "descriptionAboutAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.Theft.descriptionAboutAccident.warning
                  }
                  error_message=" Enter Brief Description about accident"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Claim Status"
                  value={inputFields.TwIns.Theft.claimStatus.value}
                  attrName={["TwIns", "Theft", "claimStatus"]}
                  value_update={fieldsUpdateState}
                  data={typesOfClaimStatus}
                  warn_status={inputFields.TwIns.Theft.claimStatus.warning}
                  error_message="Select Claim status"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={inputFields.TwIns.Theft.whatDoYouExpect.value}
                  attrName={["TwIns", "Theft", "whatDoYouExpect"]}
                  value_update={fieldsUpdateState}
                  warn_status={inputFields.TwIns.Theft.whatDoYouExpect.warning}
                  error_message=" Enter What do you expect us to do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
          {/* Third Party Claim */}
          <TabPanel
            className="tabPanel ownDamage"
            value="Third Party Claim"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid xs={12} className="gridHeading ownHead">
              Third Party Claim Requirements
            </Grid>
            <Grid container spacing={3}>
              {/* <Grid container  gap="20px"> */}
              <Grid xs={12} sm={6} md={3} lg={3}>
                <RKTextField
                  class_name="inputField vehicleNumberField"
                  title={""}
                  value={inputFields.TwIns.ThirdParty.vehicleNumber.value}
                  attrName={["TwIns", "ThirdParty", "vehicleNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.vehicleNumber.warning
                  }
                  error_message={
                    isEmpty(inputFields.TwIns.ThirdParty.vehicleNumber.value)
                      ? "Enter vehicle number"
                      : "Enter valid vehicle number"
                  }
                  placeholder={"DL01A2345"}
                  validation_type="ALL_CAPS"
                  max_length={11}
                />
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={9}
                lg={9}
                sx={{ display: "flex", alignItems: "center" }}>
                <div className="fileUpload">
                  <input
                    type="file"
                    ref={TWcopyOfRCThird}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange("copyOfRC")}
                    accept="image/*"
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfRC")}>
                    <CloudUploadIcon />
                    {inputFields.TwIns.ThirdParty.copyOfRC.docFile?.name
                      ? inputFields.TwIns.ThirdParty.copyOfRC.docFile?.name
                      : "Browse to upload copy of RC"}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <CustomDateTimePicker
                  class_name="inputField"
                  title={"Date & Time of Accident"}
                  value={inputFields.TwIns.ThirdParty.dateTimeOfAccident.value}
                  attrName={["TwIns", "ThirdParty", "dateTimeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.dateTimeOfAccident.warning
                  }
                  error_message={" Enter Date & Time of Accident"}
                  date_validation_type="MONTHS"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Place of Accident"}
                  value={inputFields.TwIns.ThirdParty.placeOfAccident.value}
                  attrName={["TwIns", "ThirdParty", "placeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.placeOfAccident.warning
                  }
                  error_message={" Enter Place of Accident"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Driver at the Time of Accident"}
                  value={
                    inputFields.TwIns.ThirdParty.driverAtTimeOfAccident.value
                  }
                  attrName={["TwIns", "ThirdParty", "driverAtTimeOfAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.driverAtTimeOfAccident.warning
                  }
                  error_message={" Enter Driver at the Time of Accident"}
                />
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Driving Licence no."}
                  value={inputFields.TwIns.ThirdParty.drivingLicenseNo.value}
                  attrName={["TwIns", "ThirdParty", "drivingLicenseNo"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.drivingLicenseNo.warning
                  }
                  error_message={" Enter Driving Licence no."}
                  max_length={20}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  class_name="inputField"
                  title={"Driving Licence valid upto"}
                  value={
                    inputFields.TwIns.ThirdParty.drivingLicenseValidUpto.value
                  }
                  attrName={["TwIns", "ThirdParty", "drivingLicenseValidUpto"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Driving Licence valid upto"
                  warn_status={
                    inputFields.TwIns.ThirdParty.drivingLicenseValidUpto.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Type of licence"}
                  value={
                    inputFields.TwIns.ThirdParty.typeOfDrivingLicense.value
                  }
                  attrName={["TwIns", "ThirdParty", "typeOfDrivingLicense"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.typeOfDrivingLicense.warning
                  }
                  error_message={" Enter Type of licence"}
                  data={typesOfDrivingLicense}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={8}
                sx={{ display: "flex", alignItems: "center" }}>
                <Box className="fileUpload file1">
                  <input
                    type="file"
                    ref={TWcopyOfDrivingLicenseThird}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange("copyOfDrivingLicense")}
                    accept="image/*"
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfDrivingLicense")}>
                    <CloudUploadIcon />
                    {inputFields.TwIns.ThirdParty.copyOfDrivingLicence.docFile
                      ?.name
                      ? inputFields.TwIns.ThirdParty.copyOfDrivingLicence
                          .docFile?.name
                      : "Browse to upload copy of Driving Licence"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <div className="claimPolicyDoc">
                  <h5>Reported at Police Station?</h5>
                  <Box sx={{ padding: "0 !important" }}>
                    <CustomButton
                      text_name={"Yes"}
                      class_name={`greenMdBtn mr-4 ${
                        inputFields.TwIns.ThirdParty.reportedAtPoliceStation
                          ? "yes"
                          : "nope"
                      }`}
                      onClickFunction={() => {
                        setInputFields({
                          ...inputFields,
                          TwIns: {
                            ...inputFields.TwIns,
                            ThirdParty: {
                              ...inputFields.TwIns.ThirdParty,
                              reportedAtPoliceStation: true,
                            },
                          },
                        });
                      }}
                    />
                    <CustomButton
                      text_name={"No"}
                      class_name={`greenMdBtn ${
                        inputFields.TwIns.ThirdParty.reportedAtPoliceStation
                          ? "nope"
                          : "yes"
                      }`}
                      onClickFunction={() => {
                        setInputFields({
                          ...inputFields,
                          TwIns: {
                            ...inputFields.TwIns,
                            ThirdParty: {
                              ...inputFields.TwIns.ThirdParty,
                              reportedAtPoliceStation: false,
                            },
                          },
                        });
                      }}
                    />
                  </Box>
                </div>
              </Grid>
              {inputFields.TwIns.ThirdParty.reportedAtPoliceStation ? (
                <Grid xs={12} sm={12} md={6} lg={6}>
                  <div className="claimPolicyDoc">
                    <Box className="fileUpload">
                      <input
                        type="file"
                        ref={TWcopyOfComplaintThird}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange("copyOfComplaint")}
                        accept="image/*"
                      />
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("copyOfComplaint")}>
                        <CloudUploadIcon />
                        {inputFields.TwIns.ThirdParty.copyOfComplaint.docFile
                          ?.name
                          ? inputFields.TwIns.ThirdParty.copyOfComplaint.docFile
                              ?.name
                          : "Browse to upload copty of complaint"}
                      </Button>
                    </Box>
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Select Insurer"
                  value={inputFields.TwIns.ThirdParty.insurer.value}
                  attrName={["TwIns", "ThirdParty", "insurer"]}
                  value_update={fieldsUpdateState}
                  data={insurerCompanies}
                  warn_status={inputFields.TwIns.ThirdParty.insurer.warning}
                  error_message="Select Insurer"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <RKTextField
                  class_name="inputField"
                  title={"Policy no."}
                  value={inputFields.TwIns.ThirdParty.policyNumber.value}
                  attrName={["TwIns", "ThirdParty", "policyNumber"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.policyNumber.warning
                  }
                  error_message={" Enter Policy no."}
                  max_length={20}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy Start Date"}
                  value={inputFields.TwIns.ThirdParty.policyStartDate.value}
                  attrName={["TwIns", "ThirdParty", "policyStartDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy Start Date"
                  warn_status={
                    inputFields.TwIns.ThirdParty.policyStartDate.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy End Date"}
                  value={inputFields.TwIns.ThirdParty.policyEndDate.value}
                  attrName={["TwIns", "ThirdParty", "policyEndDate"]}
                  value_update={fieldsUpdateState}
                  error_message="Enter Policy End Date"
                  warn_status={
                    inputFields.TwIns.ThirdParty.policyEndDate.warning
                  }
                  date_validation_type="MONTHS"
                  min_date={1200}
                  max_date={216}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>Policy Document:</h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={TWcopyOfPolicyDocThird}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange("copyOfPolicyDoc")}
                      accept="image/*"
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPolicyDoc")}>
                      <CloudUploadIcon />
                      {inputFields.TwIns.ThirdParty.copyOfPolicyDoc.docFile
                        ?.name
                        ? inputFields.TwIns.ThirdParty.copyOfPolicyDoc.docFile
                            ?.name
                        : "Browse to upload Policy Document"}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <div className="claimPolicyDoc1">
                  <h5>Accident Photos/Images, if any</h5>
                  <>
                    <input
                      type="file"
                      ref={TWaccidentPhotosThird}
                      style={{ display: "none" }}
                      onChange={() => multiFileChange("accidentPhotos")}
                      accept="image/*"
                      multiple
                    />
                    <span onClick={() => selectFile("accidentPhotos")}>
                      {" "}
                      + Add More{" "}
                    </span>
                  </>
                </div>
              </Grid>
            </Grid>

            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                {inputFields.TwIns.ThirdParty.accidentPhotos?.length > 0 ? (
                  inputFields?.TwIns?.ThirdParty?.accidentPhotos?.map(
                    (item, i) => (
                      <div className="claimPolicyDoc1">
                        <Box className="fileUpload file1" padding="0px">
                          <Button className="cloudButton" variant="contained">
                            <CloudUploadIcon />
                            {item?.name}
                          </Button>
                        </Box>
                        <Box>
                          <Button onClick={() => removePhoto(i)}>Remove</Button>
                        </Box>
                      </div>
                    )
                  )
                ) : (
                  <div className="claimPolicyDoc">
                    <Box className="fileUpload file1" padding="0px">
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("accidentPhotos")}>
                        <CloudUploadIcon />
                        Browse to upload the images
                      </Button>
                    </Box>
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Brief Description about accident"}
                  value={
                    inputFields.TwIns.ThirdParty.descriptionAboutAccident.value
                  }
                  attrName={["TwIns", "ThirdParty", "descriptionAboutAccident"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.descriptionAboutAccident
                      .warning
                  }
                  error_message=" Enter Brief Description about accident"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Details of third party deaths if any"}
                  value={inputFields.TwIns.ThirdParty.detailsOfThirdParty.value}
                  attrName={["TwIns", "ThirdParty", "detailsOfThirdParty"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.detailsOfThirdParty.warning
                  }
                  error_message={" Enter Details of third party deaths if any"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Details of third party body injuries if any"}
                  value={inputFields.TwIns.ThirdParty.deathOfThirdParty.value}
                  attrName={["TwIns", "ThirdParty", "deathOfThirdParty"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.deathOfThirdParty.warning
                  }
                  error_message={
                    " Enter Details of third party body injuries if any"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Details of third party property damage if any"}
                  value={
                    inputFields.TwIns.ThirdParty.detailsOfThirdPropertyDamage
                      .value
                  }
                  attrName={[
                    "TwIns",
                    "ThirdParty",
                    "detailsOfThirdPropertyDamage",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.detailsOfThirdPropertyDamage
                      .warning
                  }
                  error_message={
                    " Enter Details of third party property damage if any"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={3}>
                <SelectDropdown
                  class_name="inputField"
                  title="Claim Status"
                  value={inputFields.TwIns.ThirdParty.claimStatus.value}
                  attrName={["TwIns", "ThirdParty", "claimStatus"]}
                  value_update={fieldsUpdateState}
                  data={typesOfClaimStatus}
                  warn_status={inputFields.TwIns.ThirdParty.claimStatus.warning}
                  error_message=" Select Claim Status"
                />
              </Grid>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={inputFields.TwIns.ThirdParty.whatDoYouExpect.value}
                  attrName={["TwIns", "ThirdParty", "whatDoYouExpect"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.TwIns.ThirdParty.whatDoYouExpect.warning
                  }
                  error_message=" Enter What do you expect us to do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </>
  );
};

export default TwoWheelerClaim;
