import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginContainer from "../../../Container/MyAccount/LoginContainer/LoginContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { MY_ACCOUNT_ROUTES } from "../../../Router/Path/MyAccountRoutes";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { HomeSlice } from "../../../Store/Slice_Reducer/Home/HomeSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import Buttons from "../../Common/CustomButton/CustomButton";
import "./Navbar.scss";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const currentPath = location.pathname;
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [transparentBg, setTransparentBg] = useState(true);
  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);

      if (position > 70) {
        setTransparentBg(false);
      } else {
        setTransparentBg(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    navigate("/");
  };

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const producthandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const producthandleClose = () => {
    setAnchorE2(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={`Navbar  ${transparentBg ? " " : "box-shadow"}`}>
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
      {/* <Grid container spacing={2} maxWidth="100%">
        <Grid md={12} className="top-strip">
          <p dangerouslySetInnerHTML={{ __html: header?.top_heading }} />
        </Grid>
      </Grid>   */}
      <Grid container columnSpacing={3} className="row mt-3 mb-3">
        <Grid xs="auto" className="logo-section">
          <Link onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <img
              src="../images/logo.svg"
              alt="bima-street"
              className="logo"
              onClick={() => {
                window.location.replace("/");
              }}
            />
          </Link>
        </Grid>
        <Grid xs className="nav-section text-right" alignSelf={"center"}>
          <ul>
            <li>
              <Button
                className="nav-link product-link"
                id="basic-link"
                aria-controls={open2 ? "basic-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={producthandleClick}>
                Products
              </Button>
              <Menu
                id="basic-menu2"
                anchorEl={anchorE2}
                open={open2}
                onClose={producthandleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-link",
                }}>
                <MenuItem
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                    producthandleClose();
                  }}>
                  Health Insurance
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_CAR);
                    producthandleClose();
                  }}>
                  Car Insurance
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TW);
                    producthandleClose();
                  }}>
                  Two-Wheeler Insurance
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TERM);
                    producthandleClose();
                  }}>
                  Term Insurance
                </MenuItem>
              </Menu>
            </li>
            <li style={{ flexShrink: "0" }}>
              <Link
                className="nav-link"
                onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About Us
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="ca-link"
                onClick={() => navigate(COMMON_ROUTES.CLAIMS)}>
                Claim Assistance
                <span className="boxblink">
                  <p className="blink_text">100% Free</p>
                </span>
              </Link>
            </li>

            {currentPath !== COMMON_ROUTES.PIR &&
              currentPath !== COMMON_ROUTES.PIR_REPORT && (
                <li>
                  <Link
                    className="pir-link"
                    onClick={() => navigate(COMMON_ROUTES.PIR)}>
                    Personalized Insurance Report
                    <span>
                      <p>Free</p>
                    </span>
                  </Link>
                </li>
              )}

            <li>
              <Link
                className="pos-link"
                onClick={() => {
                  window.open("https://pos.bimastreet.com/", "_blank");
                }}>
                POS
              </Link>
            </li>

            {USER_DATA.mobile.value ? (
              <li className="ctaBtn">
                <Button
                  className="greenMdBtn rounded"
                  id="basic-button"
                  aria-controls={anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl)}
                  onClick={(e) => {
                    dispatch(HomeSlice.actions.setPageStatus("MY_ACCOUNT"));
                    handleClick(e);
                  }}>
                  Hi! User
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    }}>
                    My account
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </li>
            ) : (
              <li className="ctaBtn">
                <Buttons
                  onClickFunction={() => {
                    setOpenCustomerLogin(true);
                  }}
                  class_name="greenMdBtn rounded"
                  text_name="Login"
                />
              </li>
            )}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
