import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState, lazy, Suspense } from "react";
import "react-multi-carousel/lib/styles.css";
import { EHomeSectionName } from "../../../Services/Enum/EHome";
import {
  TAwards,
  THowItWorks,
  TOurClients,
  TPartners,
  TProducts,
  TTestimonials,
  TTopRated,
} from "../../../Type/Home/THomePage";
import { useAppSelector } from "../../../Store/hooks";
import "./MHomePage.scss";
import { STRAPI_DOMAIN } from "../../../URLCollection/Domain";

// Lazy-loaded components

const MFooter = lazy(() => import("../../../Component/Mobile/MFooter/MFooter"));
const MNavbar = lazy(() => import("../../../Component/Mobile/MNavbar/MNavbar"));
const MHowItWorks = lazy(() => import("./MHowItWorks/MHowItWorks"));
const MAwards = lazy(() => import("./MAwards/MAwards"));
const MTopRated = lazy(() => import("./MTopRated/MTopRated"));
const MOurClients = lazy(() => import("./MOurClients/MOurClients"));
const MOurProducts = lazy(() => import("./MOurProducts/MOurProducts"));
const MTestimonial = lazy(() => import("./MTestimonial/MTestimonial"));
const MOurPartners = lazy(() => import("./MOurPartners/MOurPartners"));

const MHomePage = ({ navigateForm }: { navigateForm: Function }) => {
  const { Home } = useAppSelector((state) => state);
  const [arrayOfSection, setArrayOfSection] = useState(Object.entries(Home));

  useEffect(() => {
    setArrayOfSection(Object.entries(Home));
  }, [Home]);

  const returnSectionByIndex = (index: number) => {
    const visibleSection = arrayOfSection.find(
      ([, section]) =>
        (
          section as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.section_index === index &&
        (
          section as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.visible === true
    );

    if (visibleSection) {
      switch (
        (
          visibleSection[1] as
            | THowItWorks
            | TTopRated
            | TOurClients
            | TAwards
            | TProducts
            | TTestimonials
            | TPartners
        )?.section_name
      ) {
        case EHomeSectionName.HOW_IT_WORKS:
          return <MHowItWorks />;
        case EHomeSectionName.AWARDS:
          return <MAwards />;
        case EHomeSectionName.TOP_RATED:
          return <MTopRated />;
        case EHomeSectionName.OUR_CLIENTS:
          return <MOurClients />;
        case EHomeSectionName.OUR_PRODUCTS:
          return <MOurProducts />;
        case EHomeSectionName.TESTIMONIALS:
          return <MTestimonial />;
        case EHomeSectionName.OUR_PARTNERS:
          return <MOurPartners />;

        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Box className="homepageWrapper_m">
      <Suspense>
        <MNavbar />
      </Suspense>

      <div className="head-section mb-10">
        <Grid container spacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <h1 dangerouslySetInnerHTML={{ __html: Home.header?.heading }} />
            <p dangerouslySetInnerHTML={{ __html: Home.header?.desc }} />
          </Grid>
          <div className="productWrapper">
            <ul className="productList">
              {Home.our_products.data.data.map((data, index) => (
                <li className="product-type">
                  <Link
                    className="box"
                    onClick={() => {
                      navigateForm(data.attributes.type);
                    }}>
                    <img
                      alt=""
                      src={
                        STRAPI_DOMAIN +
                        data.attributes.image.data.attributes.url
                      }
                    />
                    <p>{data.attributes.title}</p>
                  </Link>
                </li>
              ))}
              {process.env.REACT_APP_ENV === "DEV" ||
              process.env.REACT_APP_ENV === "STG" ? (
                <li className="product-type">
                  <Link className="box disabled">
                    <img src={"./images/home-ins-icon.svg"} alt="img" />
                    <p>Home Insurance</p>
                  </Link>
                </li>
              ) : null}
              {process.env.REACT_APP_ENV === "DEV" ||
              process.env.REACT_APP_ENV === "STG" ? (
                <li className="product-type">
                  <Link className="box disabled">
                    <img src={"./images/travel-ins-icon.svg"} alt="img" />
                    <p>Travel Insurance</p>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        </Grid>
        <div className="bikeWrapper">
          <div className="bikebox">
            <img src="images/biker.svg" className="rider" alt="img" />
            <img src="images/hair.svg" className="hair" alt="img" />
          </div>
        </div>

        <div className="carWrapper">
          <div className="carbox">
            <img src="images/car.svg" className="carbody" alt="img" />
            <img src="images/wheel.svg" className="rearWheel" alt="img" />
            <img src="images/wheel.svg" className="frontWheel" alt="img" />
          </div>
        </div>
      </div>
      {arrayOfSection.map((data, index) => (
        <Suspense>{returnSectionByIndex(index)}</Suspense>
      ))}
      <Suspense>
        <MFooter />
      </Suspense>
    </Box>
  );
};

export default MHomePage;
